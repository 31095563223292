<template>
    <v-expansion-panels multiple
                        hover>
        <v-expansion-panel v-for="(item,i) in panelData"
                           :key="i">
            <v-expansion-panel-header>{{item[0]}}</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Name
                                </th>
                                <th class="text-left">
                                    Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in item[1]"
                                :key="item.transactionName">
                                <td>{{ item.transactionName }}</td>
                                <td><span style="color:#4CAF50">+ {{ asMoney(item.transactionBalances[0].transactionAmount) }}</span></td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>

    import { formatMoney } from '../../../../javascript/globalFunctions'
    import { returnIncomeCategoriesAndThereItems } from '../../../../javascript/widgetManager'

    export default {
        props: ['period'],
        data() {
            return {
                panelData: []
            }
        },
        computed: {
            asMoney: function () {
                return (amount) => {
                    return formatMoney(amount, 2, ".", ",")
                }
            }
        },
        methods: {
            updateTotals() {
                this.panelData = returnIncomeCategoriesAndThereItems(this.period)
            },
            
        },
        watch: {
            "period": function () {
                this.updateTotals()
            }
        },
        beforeMount() {
            this.updateTotals()
        }
    }

</script>